@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.up-sell-products {
  border-radius: 4px;
  overflow: hidden;
}

.up-sell-products__heading {
  width: 100%;
  height: 60px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  @include themify() {
    background: theme(product-promotion-header__background);
  }
}

.list__container {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  width: 400px;
}

.list__container:nth-child(3) {
  display: none
}

.up-sell-products__list {
  display: flex;
  padding: 8px;
  gap: 8px;
  @include themify() {
    background-color: theme(up-sell-products__background);
  }
}

.list__item {
  height:100%;
  @include themify() {
    width: 100%;
  }
}

.list__item__img,
.list__item__img-link {
  @include themify() {
    min-height: unset;
    aspect-ratio: 1;
  }
}

@media (min-width: (var('desktop'))) {
  .list__container:nth-child(3) {
    display: block
  }
}
