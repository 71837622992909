@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.newsletter {
  padding-bottom: 0px;
  display: block;
  width: 100%;
}

.newsletter--fixed {
  position: fixed;
  bottom: 0;
  background-color: #fff;
  padding-bottom: 0;
  z-index: 1024;
}

.newsletter--bottom {
  position: relative;
}

.newsletter__title {
  font-weight: bold;
  padding: 15px 15px;
  margin: 0;
  text-transform: uppercase;
  box-sizing: border-box;
  display: block;
  font-size: var(text--lg);
  letter-spacing: 1px;
  font-weight: bold;
  @include themify() {
    border-top: 1px solid theme(footer-block__line);
    border-bottom: 1px solid theme(footer-block__line);
  }
}

.newsletter__title--expanded {
  @include themify() {
    border-bottom: none;
  }
}

.newsletter__form {
  display: none;
  max-height: 0;
  transition: max-height 0.3s linear;
  overflow: hidden;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 15px;
}

.newsletter__button {
  margin-top: 8px;
  height: 60px;
}

.newsletter__label {
  width: 100%;
  font-weight: bold;
  font-size: var(text--md);
  padding-bottom: 5px;
  text-transform: uppercase;
  padding-top: 8px;
}

.newsletter__email {
  width: 82%;
}

.newsletter__form--expanded {
  transition: max-height 0.5s linear;
  display: flex;
  max-height: 300px;
  padding-bottom: 30px;
}

.newsletter__close-icon {
  display: none;
  position: absolute;
  bottom: 0%;
  right: 0%;
  z-index: 101;
}

.newsletter__close-icon--visible {
  display: flex;
}

@media only screen and (min-width: var('tablet')) {
  .newsletter {
    grid-row: 1;
    grid-column: 2;
    margin-bottom: 0;
  }

  .newsletter--fixed {
    position: unset;
    z-index: 100;
  }

  .newsletter__close-icon--visible {
    display: none;
  }

  .newsletter__title {
    @include themify() {
      border-bottom: 0;
    }
  }

  .newsletter__form {
    max-height: unset;
    padding: 0 15px;
    display: flex;
  }

  .newsletter__email {
    padding: 0;
    width: 75%;
  }

  .newsletter__label {
    font-weight: normal;
    font-size: var(text--sm);
  }
}
