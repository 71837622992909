@import '../../app/styles/themify.module.scss';
@import '../../app/styles/vars.module.scss';

.number-field {
  display: flex;
  align-items: center;
}

.number-field__button {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 7px 2px 2px 2px;
  width: 25px;
  height: 25px;
  padding: 5px;
}

div.number-field__value {
  flex-grow: 1;

  @include themify() {
    margin: 0px 0px;
    min-width: 41px;
    text-align: center;
    margin-top: 0px;
    //padding: 5px 8px;
    border-radius: 5px;
  }
}

.number-field__label {
  justify-content: center;
  align-items: center;
}

@media (min-width: var('phone')) {
  .number-field__value {
    //padding: 5px 15px;
    margin: 0px 5px;
  }

  .number-field__button {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: var('tablet--small')) {
  .number-field__button {
    width: 30px;
    height: 30px;
  }
  .number-field__value {
    // padding: 5px 10px;
  }
}

@media (min-width: var('desktop')) {
  .number-field__button {
    width: 40px;
    height: 40px;
  }
  .number-field__value {
    //padding: 5px 15px;
  }
}
