.pagination {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
}

.pagination__spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  min-height: 10vh;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.25) 100%);
  background: -webkit-linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.25) 100%);
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0.25) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
}

.pager {
  display: flex;
  justify-content: center;
}

.pager--left {
  justify-content: left;
}

.pager--right {
  justify-content: right;
}

.pagination--infinite__loadMore {
  width: 100%;
  height: 30px;
}

.pagination__spinner--infinite {
  background: (0, 0, 0, 0);
  align-items: center;
  min-height: unset;
  height: min-content;
  top: 20px;
  position: relative;
}

.pagination__data {
  min-height: 180px;
}
