@import '../../../app/styles/mixin.module.scss';

.item {
  padding: 0px;
  margin: 0px;
}

.button {
  margin-top: 8px;
  padding: 8px 50px;
  @include themify() {
    background: theme(color--gray);
  }
}

@media (max-width: (var('tablet'))) {
  .button {
    width: 100%;
  }
}
