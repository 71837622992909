/* #### Generated By: http://font.download #### */


@font-face {
    font-family: 'Twin Century MT';
    font-weight: normal;
    src: local('Tw Cen MT Std Regular'), url('TwCenMTStd.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT';
    font-style: normal;
    font-weight: 300;
    src: local('Tw Cen MT Std Light'), url('TwCenMTStdLight.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT';
    font-style: oblique;
    font-weight: 300;
    src: local('Tw Cen MT Std Light Italic'), url('TwCenMTStdLightItalic.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT Cond';
    font-style: normal;
    font-weight: 400;
    src: local('Tw Cen MT Std Medium Cond'), url('Tw Cen MT Std Medium Cond.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT';
    font-style: oblique;
    font-weight: 400;
    src: local('Tw Cen MT Std Italic'), url('TwCenMTStdItalic.woff') format('woff');
}



@font-face {
    font-family: 'Twin Century MT Cond';
    font-style: normal;
    font-weight: 700;
    src: local('Tw Cen MT Std Bold Cond'), url('Tw Cen MT Std Bold Cond.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT';
    font-style: normal;
    font-weight: 700;
    src: local('Tw Cen MT Std Bold'), url('TwCenMTStdBold.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT';
    font-style: oblique;
    font-weight: 700;
    src: local('Tw Cen MT Std Bold Italic'), url('Tw Cen MT Std Bold Italic.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT';
    font-style: normal;
    font-weight: 900;
    src: local('Tw Cen MT Std Extra Bold'), url('TwCenMTStdExtraBold.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT Cond';
    font-style: normal;
    font-weight: 900;
    src: local('Tw Cen MT Std Extra Bold Cond'), url('TwCenMTStdExtraBoldCond.woff') format('woff');
}


@font-face {
    font-family: 'Twin Century MT';
    font-style: oblique;
    font-weight: 900;
    src: local('Tw Cen MT Std Extra Bold It'), url('twcenmtstdextraboldit.woff') format('woff');
}