@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';

.tab {
  max-width: 33%;
  width: 100%;
  min-height: 60px;
}

.tab--selected {
  @include themify() {
    background: theme(color--secondary);
    border-color: theme(color--secondary);
  }
}

.tab__label {
  font-size: 0.9rem;
}

.tab--ripple {
  @include themify() {
    background: theme(color--active);
  }
}

@media screen and (min-width: var('tablet')) {
  .tabs {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
