@import '../../../../app/styles/themify.module.scss';
@import '../../../../app/styles/vars.module.scss';

.mega-menu {
  position: relative;
  @include themify() {
    background-color: theme(mega-menu__background--level1);
  }
}

.mega-menu__list {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.search-bar {
  position: absolute;
  display: none;
  @include themify() {
    background-color: theme(mega-menu__background--level1);
  }
}

.search-bar--active {
  display: block;
  width: 100%;
  top: 0;
  z-index: 900;
}

.search-bar__container {
  width: 70%;
  min-width: 300px;
  display: flex;
  margin: 0 auto;
  height: var(menu-height);
}

.search-bar__label {
  display: flex;
  margin: 0 15px;
  height: var(menu-height);
  align-items: center;
  text-transform: uppercase;
  font-size: var(text--md);
}

// .search-bar__input {
//   width: 100%;
//   font-size: var(text--md);
//   @include themify() {
//     background-color: theme(mega-menu__background--level1);
//     color: theme(mega-menu__text--level1);
//   }
// }

.search {
  @include themify() {
    height: 48px;
    width: 78%;

    margin: 0px;
    background-color: theme(mega-menu__background--level1);
    color: theme(mega-menu__text--level1);

    & fieldset {
      border: none;
    }
  }
}

.search__input {
  @include themify() {
    font-size: var(text--md);
    height: 100%;
    line-height: normal;
    margin: 0px;
    text-transform: uppercase;
  }
}

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(menu-height);
  width: var(menu-height);
  min-width: var(menu-height);
  flex-grow: 0;
}

.btn-icon--clickable {
  cursor: pointer;
  position: absolute;
  right: 0;
}

.btn-icon--clickable:hover {
  @include themify() {
    background-color: theme(mega-menu__background--level1);
    color: theme(mega-menu__text--level1);
  }
}

.close-icon:hover {
  @include themify() {
    fill: theme(color__link--active);
  }
}

.search-icon {
  display: flex;
  font-size: 2rem;
  position: relative;
}

.btn-search-close {
  position: relative;
  left: -21px;
  min-width: unset;
  width: auto;
}
