@import '../../app/styles/themify.module.scss';
@import '../../app/styles/vars.module.scss';

.search-box {
  position: relative;
  display: flex;
  width: 100%;
}

.suggestions {
  width: 100%;
  min-width: 300px;
  position: absolute;
  top: var(menu-height);
  margin: 0;
  height: auto;
  max-height: 70vh;
  list-style-type: none;
  overflow: hidden;
  padding: 0;
  @include themify() {
    background-color: theme(mega-menu__background);
  }
}

.suggestion {
  width: 100%;
  height: 0;
  opacity: 0;
  transition: all 200ms ease-in;
  overflow: hidden;
  @include themify() {
    border-bottom: 1px solid theme(color--gray-light);
  }
}
.suggestion:last-child {
  @include themify() {
    border-bottom: 0px;
  }
}
.suggestion__link,
.suggestion__show-more {
  display: block;
  padding-left: 15px;
  line-height: var(menu-height);
  text-transform: uppercase;
  color: var(color-link);
}

.suggestion__show-more {
  text-align: center;
  padding-left: 0px;
  cursor: pointer;
}

.suggestion__show-more:hover,
.suggestion__show-more--active,
.suggestion__link:hover,
.suggestion__link--active {
  @include themify() {
    background: theme(mega-menu__background--level1);
    color: theme(mega-menu__text--level1);
  }
}

.suggestion--enter-active,
.suggestion--enter-done {
  min-height: var(menu-height);
  opacity: 1;
  height: auto;
}

.suggestion--exit-active,
.suggestion--exit-done {
  height: 0;
  opacity: 0;
}
