@import '../../app/styles/themify.module.scss';
@import '../../app/styles/vars.module.scss';

$bar-height: 35px;

// To refactor
:global {
  .auth-menu {
    display: none;

    &.show {
      display: block;

      &::before {
        content: '';
        opacity: 1;
        width: 0;
        height: 0;
        top: 70px;
        right: 84px;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        @include themify() {
          border-bottom: 10px solid theme(auth-menu__border);
        }
      }
    }

    &::before {
      opacity: 0;
      transition: all 200ms ease-in;
    }

    > .content {
      position: absolute;
      top: 80px;
      z-index: 256;
      width: 440px;
      right: 0;
      @include themify() {
        background: theme(auth-menu__background);
        border: 1px solid theme(auth-menu__border);
      }

      .header {
        display: flex;
        height: var('menu-height');
        align-items: center;
        font-weight: bold;
        padding: 0 0 0 30px;

        > .name {
          text-align: left;
          flex-grow: 1;
        }

        > .logout {
          display: block;
          margin: 0 15px;
          word-wrap: break-word;
        }

        > .btn-close-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: var('menu-height');
          height: var('menu-height');
          cursor: pointer;
        }
      }

      ul[role='menu'] {
        > li {
          padding: 0;
          height: auto;
          @include themify() {
            background: theme(mega-menu__background);
          }

          &,
          > a {
            display: block;
          }

          > a {
            padding: 15px 30px;
            font-family: 'Montserrat', sans-serif;
          }
        }
      }
    }
  }
}

.header {
  display: block;
  float: left;
  clear: both;
  width: 100%;
  padding: 0px 0px;
  align-self: center;
}

.bar__simple-message {
  padding: 10px 5px 10px 15px;
  font-size: var('text--xs');
  text-transform: uppercase;
  float: left;
  width: 55%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  @include themify() {
    background: theme(header__background);
    color: theme(header-message__text);
  }
}

.bar__opposite-logo {
  width: 45%;
}

.header__middle {
  min-height: 80px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
}

.middle__logo {
  font-family: Arial Black;
  font-size: var('text-size-main-logo');
  line-height: var('text-size-main-logo');
  text-transform: uppercase;
  padding: 20px 67px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;

  > a,
  a:hover {
    @include themify() {
      color: theme(header-logo__text);
    }
    text-decoration: none;
  }

  > .club-logo {
    font-family: Arial Black;
    height: 1rem;
  }
}

.middle__hamburger {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15px;
  position: absolute;
  top: 0;
  height: 100%;
  .ii {
    font-size: var('text-size-main-logo');
    @include themify() {
      color: theme(header__icon);
    }
  }
}

.middle__actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  padding-right: 15px;

  > .logout-icon {
    margin-bottom: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  > .ii {
    font-size: var('text--xxl');
    @include themify() {
      color: theme(header__icon);
    }
    margin: 0px 5px 0px 5px;
  }

  > a {
    margin-right: 15px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover,
    &:focus {
      cursor: pointer;
    }

    &.profile {
      &::after {
        content: '';
        margin-left: 15px;
        @include themify() {
          border-right: 1px solid theme(auth-menu__link--border);
        }
      }
    }
  }
}

@media (min-width: var('phone')) {
  .header {
    grid-template-columns: 1fr;
    display: grid;
  }

  .bar__simple-message {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
  }

  .bar__opposite-logo {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
    font-size: var('text--xl');
  }

  .header__bar {
    display: grid;
    grid-template-columns: 3fr 2fr;
  }
}

@media (min-width: var('small-tablet')) {
  .header__bar {
    grid-template-columns: 4fr 2fr;
  }
}

@media (min-width: var('tablet')) {
  .header {
    grid-template-columns: 1fr;
    display: grid;
  }

  .bar__simple-message {
    grid-row: 1;
    grid-column: 3;
    width: 100%;
    justify-content: flex-end;
    @include themify() {
      border-bottom: 1px solid theme(header__border--bottom);
    }
  }

  .bar__opposite-logo {
    grid-row: 1;
    grid-column: 4;
    width: 100%;
    @include themify() {
      border-bottom: 1px solid theme(header-logo__border--bottom--opposite);
    }
  }

  .header__bar {
    display: grid;
    grid-template-columns: 1fr 6fr 6fr 3fr;
  }

  .bar__simple-message {
    padding-right: 25px;
  }

  .header__middle {
    padding: 0px 10px;
  }

  .middle__logo {
    padding: 20px 0px;
  }

  .middle__actions {
    padding-right: unset;
  }

  .bar__localization-panel {
    position: relative;
    grid-row: 2;
    grid-column: 1;
    grid-column-end: 4;

    .content .language-switcher,
    .content .currency-switcher {
      margin-top: 5px;
      margin-right: 20px;
    }
    .content > svg {
      cursor: pointer;
    }
  }
}

@media (min-width: var('laptop')) {
  .header__bar {
    grid-template-columns: 1fr 6fr 6fr 3fr;
  }
}

.club-description {
  display: flex;
  padding: 10px 25px 10px;
  justify-content: center;
  text-align: center;
}
