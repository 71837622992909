.location {
  margin-top: 5px;
}

.box {
  display: flex;
  align-items: center;
}

.flag__icon {
  margin-left: 10px;
}