@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';

.catalog-view-switch__container {
  position: relative;
}

.catalog-view-switch__icon {
  font-size: 24px;
  @include themify() {
    color: theme(color--primary);
  }
}

.catalog-view-switch__icon--left,
.catalog-view-switch__icon--right {
  position: absolute;
  top: 50%;
  height: 24px;
  width: 24px;
  pointer-events: none;
}

.catalog-view-switch__icon--left {
  left: 25%;
  transform: translate(-50%, -50%);
}

.catalog-view-switch__icon--right {
  right: 25%;
  transform: translate(50%, -50%);
}

.catalog-view-switch__switch__root {
  padding: 0;
  width: 100%;
  height: 50px;
}

.catalog-view-switch__switch__switch-base {
  padding: 3px;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  @include themify() {
    color: theme(catalog-view-switch__thumb);
  }
  &:hover {
    background-color: transparent;
  }
}

.catalog-view-switch__switch__switch-base.catalog-view-switch__switch--checked {
  transform: translateX(50%);
}

.catalog-view-switch__switch__switch-base.catalog-view-switch__switch--checked+.catalog-view-switch__switch__track {
  opacity: 1;
  @include themify() {
    background-color: theme(catalog-view-switch__track);
  }
}

.catalog-view-switch__switch__thumb {
  height: 100%;
  border-radius: 0;
  width: calc(50% - 3px);
  box-shadow: none;
}

.catalog-view-switch__switch--checked {
  @include themify() {
    color: theme(catalog-view-switch__thumb);
  }
}

.catalog-view-switch__switch__track {
  border-radius: 0;
  opacity: 1;
  @include themify() {
    background-color: theme(catalog-view-switch__track);
  }
}