@import '../../app/styles/vars.module.scss';
@import '../../app/styles/mixin.module.scss';

.notification-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 6%;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  width: 88%;
}

.notification-container--reversed {
  flex-direction: column-reverse;
}

:global(.shop.hide) .notification-container {
  display: none !important;
}

.snackbar {
  position: static;
  transform: translateX(0);
}

.snackbar--bottom-center {
  left: 50%;
}

.snackbar__content--success {
  @include themify() {
    background-color: theme('snackbar__background--success');
  }
}

.snackbar__content--error {
  @include themify() {
    background-color: theme('snackbar__background--error');
  }
}

.snackbar__content--info {
  @include themify() {
    background-color: theme('snackbar__background--info');
  }
}

.snackbar__content--warning {
  @include themify() {
    background-color: theme('snackbar__background--warning');
  }
}

.notification {
  margin: var('spacing__unit');
}

.notification__icon {
  font-size: var('text--xxl');
  opacity: 0.9;
}

.notification__icon--left {
  margin-right: 5px;
}

.notification__message {
  display: flex;
  align-items: center;
}

.snackbar__content {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-grow: initial;
  @include themify() {
    color: theme('snackbar__text');
  }
}
.snackbar__content--info {
  @include themify() {
    color: theme('snackbar__text--info');
  }
}

.snackbar__action {
  padding-left: 0px;
  margin-left: unset;
  margin-left: 5px;
}

@media all and (min-width: var('tablet--small')) {
  .notification-container {
    left: 20%;
    width: 60%;
  }
}

@media all and (min-width: var('tablet')) {
  .notification-container {
    left: 27%;
    width: 46%;
  }
}

@media all and (min-width: var('desktop')) {
  .notification-container {
    left: 33%;
    width: 34%;
  }
}
