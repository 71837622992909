@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/cms.module.scss';

.size-guide {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.container__article {
  order: 2;
  margin-top: 30px;
}

.header {
  text-transform: uppercase;
  font-weight: bold;
  margin: 15px 0 0 0;
  padding: 15px 0;
  text-align: center;
  font-size: var(text--xxl);
  @include cms-headline--mixin;
}

@media only screen and (min-width: var(tablet)) {
  .container {
    flex-direction: row;
  }

  .container__article {
    flex: 1 0;
    margin-top: 15px;
  }
}
