@import '../../app/styles/themify.module.scss';
@import '../../app/styles/vars.module.scss';

$bar-height: 35px;

.top-message {
  text-transform: uppercase;
  font-size: var('text--xs');
  text-align: center;
  line-height: (var('text--xs') + 0.2rem);
  display: grid;
  clear: both;
  width: 100%;
  position: relative;
  height: $bar-height;
  padding: 10px 0;
  margin: 0;
  font-size: var('text--xs');
  @include themify() {
    background: theme(header-top-message__background);
  }
}

.icon-close {
  align-self: center;
  justify-self: self-end;
  font-size: var('text--xxl');
  position: absolute;
  top: 6px;
  right: 10px;
}

@media (min-width: var('phone')) {
  .top-message {
    grid-template-columns: 1fr 1px;
    grid-row: 2;
    grid-column: 1;
    grid-column-end: 3;
  }
}

@media (min-width: var('tablet')) {
  .top-message {
    grid-template-columns: 1fr 1px;
    grid-row: 1;
    grid-column: 2;
    grid-column-end: 2;
    display: flex;
    align-self: center;
    @include themify() {
      background: theme(header__background);
      color: theme(header-message__text);
      border-bottom: 1px solid theme(header__border--bottom);
    }
  }

  .top-message__text {
    text-align: left;
    margin-left: 25px;
  }
}
