@import '../../../../app/styles/themify.module.scss';
@import '../../../../app/styles/vars.module.scss';

.up-sell-mobile-modal__modal_header__button {
  height: 54px;
}

.up-sell-mobile-modal__content {
  @include themify() {
    margin: 54px 0 108px 0;
    height: calc(100% - 162px);
  }
}

.content__list {
  padding: 10px
}

.content__summary {
  margin-inline: -10px;
  width: calc(100% + 20px);
  margin-top: 2px;
  padding: 0;
  @include themify() {
    border-top: 1px solid theme(mini-cart__border);
  }
}

.up-sell-mobile-modal__total__container {
  @include themify() {
    border-top: 1px solid theme(mini-cart__border);
  }
}

.up-sell-mobile-modal__total {
  padding: 10px;
  grid-template-columns: 1fr auto 40px auto;
  grid-template-rows: 1fr;
  display: grid;
  width: 100%;
  height: auto;
}

.up-sell-mobile-modal__total__item-counter:first-of-type {
  grid-column: 2;
  white-space: nowrap;
}

.up-sell-mobile-modal__total__item-counter:last-of-type {
  grid-column: 4;
  grid-template-columns: repeat(2, auto);
}