@import '../../app/styles/vars.module.scss';

.catalog__container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (min-width: var('tablet')) {
  .catalog__container {
    margin-top: 0px;
  }
}
