@import '../../../app/styles/vars.module.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var('page__padding--top') var('page__padding--sides');
  width: 100%;
}

.container__heading {
  max-width: 650px;
}

.container__form {
  max-width: 350px;
  width: 100%;
}
