@import '../../app/styles/themify.module.scss';

.rollover {
  position: relative;
}

.img {
  position: relative;
  z-index: 2;
  display: block;
}
.img--skeleton {
  transition: none;
}
.img.img--hidden {
  opacity: 0;
  z-index: 1;

  @include themify() {
    display: none;
  }
}
