@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';

.content__image {
  max-height: 650px;
}

.content {
  display: flex;
}

@media only screen and (min-width: var(tablet)) {
  .content {
    padding: 15px 15px 15px 30px;
  }

  .content__image {
    margin: 0 0 15px 0;
  }
}
