@import '../../app/styles/vars.module.scss';
@import '../../app/styles/themify.module.scss';


.recommend_channel__dialog {
  position: fixed;
  border-radius: 4px;
  padding: 10px;
  width: calc(100% - 10px);
  bottom: 5px;
  left: 5px;
  right: 5px;
  z-index: 1500;
  @include themify {
    box-shadow:  theme(recommend-channel__box-shadow);
    background-color:  theme(recommend-channel__background-color);
  }
}

.dialog__message {
  margin-bottom: 16px;
  width: 100%;
}

.dialog__actions {
  display: flex;
  gap: 8px;
}

.actions__button {
  flex-grow: 1;
  width: 50%;
}

@media (min-width: (var('tablet--small'))) {
  .recommend_channel__dialog {
    bottom: 20px;
    right: 20px;
    left: unset;
    max-width: 400px;
  }
}