@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.search {
  padding: 0px 10px;
  padding-top: 5px;
  @include themify() {
    width: 100%;
    height: 100%;
    margin: 0px;
    background-color: theme(mega-menu__background--level1);
    color: theme(mega-menu__text--level1);

    & fieldset {
      border: 0;
      // border-top: 0px;
      // border-left: 0px;
      // border-right: 0px;
      // border-radius: 0;
    }
  }
}

.search__input {
  @include themify() {
    font-size: var(text--md);
    height: 80%;
    line-height: normal;
    margin: 0px;
    text-transform: uppercase;
  }
}

.results {
  padding: 0px 0px;
}

.suggestion {
  width: 100%;
  height: 0;
  opacity: 0;
  transition: all 200ms ease-in;
  overflow: hidden;
}
