@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';

.mobile-menu {
  position: absolute;
  margin: 15px 0 0 0;
  padding: 0;
  list-style-type: none;
  left: 0;
  right: 0;
  width: 100%;
}

.mobile-menu.goto-next-enter-active,
.mobile-menu.goto-next-exit-active,
.mobile-menu.goto-prev-enter-active,
.mobile-menu.goto-prev-enter + .mobile-menu.goto[class$='mobile-menu__item--active'] {
  transition: all 200ms ease-in;
}
.mobile-menu.goto-next-enter {
  opacity: 0.5;
  transform: translateX(-100%);
}
.mobile-menu.goto-next-enter-active {
  opacity: 1;
  transform: translateX(0);
}
.mobile-menu.goto-next-exit {
  opacity: 1;
  transform: translateX(0) scale(1);
}
.mobile-menu.goto-next-exit-active {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-menu.goto-prev-enter {
  opacity: 0;
  transform: scale(0.8);
}
.mobile-menu.goto-prev-enter-active {
  opacity: 1;
  transform: scale(1);
}
.mobile-menu.goto-prev-enter + .mobile-menu.goto {
  opacity: 1;
  transform: translateX(0);
}
.mobile-menu.goto-prev-enter + .mobile-menu.goto[class$='mobile-menu__item--active'] {
  opacity: 0.5;
  transform: translateX(-100%);
}

.mobile-menu__item {
  @include themify() {
    border-bottom: 1px solid theme(footer-block__line);
  }
}

.mobile-menu__link {
  color: var(color-link);
}

.mobile-menu__label {
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 15px;
  font-size: var(text--md);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.mobile-menu__label--active {
  color: var(color-link);
  @include themify() {
    background: theme(mega-menu__background--level1);
  }
}

.mobile-menu__back::before {
  content: '';
  position: absolute;
  top: 17px;
  left: 20px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid var(color-link);
}

.mobile-menu__label--back {
  cursor: pointer;
  font-weight: normal;
}

.mobile-menu__image {
  border-bottom: none;
  display: block;
  margin: 15px auto;
  width: calc(100% - 30px);
  height: auto;
}
