//Example Phone max is 500px (including) so from 501 small tablet starting, in mose of case we use those values for min-width and that is reason of 1px extra
// e.g to show some items only on screen bigger than tablet we use media query with min-width: var('tablet')
$vars: (
  'phone': 501px,
  'tablet--small': 769px,
  'tablet': 1025px,
  'desktop': 1369px,
  //TODO probably to remove
  'page__width--min': 450px,
  'page__width--max': 1600px,
  //base font size 14px
  'text--xs': 0.7rem,
  //10px
  'text--sm': 0.85rem,
  //12px
  'text--md': 1rem,
  //14px
  'text--lg': 1.14rem,
  //16px
  'text--xl': 1.28rem,
  //18px
  'text--xxl': 1.42rem,
  //20px
  'text--xxxl': 1.8rem,
  //25px
  'text--4xl': 2.188rem,
  //35px
  'text--5xl': 2.6rem,
  // 36.4px
  'text--h1': 2rem,
  //28px
  'page__padding--top': 20px,
  'page__padding--sides': 15px,
  'spacing__unit': 8px,
  'text-size-main-logo': 2.3rem,
  'menu-height': 48px,
  'sidebar--width': 85%,
  'sidebar--maxWidth': 380px,
  'sidebar__toolbar--height': 48px,
  'content__maxWidth--compact': 700px,
  'header__line-height': 2.14rem
);

//text sizes base size = 14px
$text-xs: 0.7rem; //10px;
$text-sm: 0.85rem; //12px;
$text-md: 1rem; //14px;
$text-lg: 1.14rem; //16px;
$text-xl: 1.28rem; //18px;
$text-xxl: 1.42rem; //20px;
$text-xxxl: 1.8rem; //25px
$text-4xl: 2.188rem; //35px
$text-5xl: 2.6rem; //35px
$text-h1: 2rem; //28px

:export {
  @each $key, $val in $vars {
    #{$key}: $val;
  }
}

@function var($name) {
  @return map_get($vars, $name);
}
