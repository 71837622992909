@import '../../../app/styles/vars.module.scss';

.form__submit {
  display: 'flex';
  flex-grow: 1;
  margin: '10px 0px 0px 0px';
  width: '100%';
}

.form__field__tooltip {
  font-size: var('text--sm');
  margin-top: 8px;
}

.form__element--hidding {
  display: flex;
  transition: opacity 600ms;
  opacity: 0;
}

.form__element--hidden {
  height: 0px;
  margin: 0px;
  display: none;
}

.form__field__tooltip-box {
  display: flex;
}
