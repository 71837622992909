@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.mobile-tab {
  display: flex;
  @include themify() {
    border-top: 1px solid theme(size-guide-mobile-tabs__border);
  }
}

.mobile-tab--open {
  @include themify() {
    border-bottom: 1px solid theme(size-guide-mobile-tabs__border);
  }
}

.mobile-tab__arrow {
  margin: 15px;
}

.mobile-tab__arrow--hide {
  display: none;
}

.mobile-tab__label {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: var(text--md);
  font-weight: bold;
}
