@import './funkita.module.scss';
@import './funkytrunks.module.scss';

$themes: (
  'funkita-theme': $funkita,
  'funkytrunks-theme': $funkytrunks,
);

$current-theme: null !default;

@mixin themify($attr: null, $themes: $themes) {
  @each $theme, $map in $themes {
    // Define current theme
    $current-theme: map-get($themes, $theme) !global;
    // Themify
    @if $attr == null {
      //Miten: added :global
      :global(.#{$theme}) & {
        @content;
      }
    } @else {
      &[data-#{$attr}='#{$theme}'] {
        @content;
      }
    }
    // reset current theme
    $current-theme: null !global;
  }
}

@function theme($query, $theme: $current-theme) {
  @return map-get($theme, $query);
}

// Themify example
// ---------------
// There are two possible outputs for the themify mixin.
// 1) It outputs the theme as a parent selector
// 2) It outputs the theme as an attribute
//
// Example 1: Standard Themify by outputting the theme as the parent selector
// ---------------
// Input
// .standard-themify {
//   @include themify() {
//     color: theme(color);
//     background: theme(background);
//   }
// }
//
// Output
// .theme1 .standard-themify {
//   color: #2FD1AF; }

// .theme2 .standard-themify {
//   color: #4FA4DD; }
//
// Example 2: Output the theme as an attribute
// ---------------
// Input
// .attribute-themify {
//   @include themify(theme) {
//     color: theme(color);
//     background: theme(background);
//   }
// }
//
// Output:
// .attribute-themify[data-theme="theme1"] {
//   color: #2FD1AF;
//   background: #FBFCFC;
// }

// .attribute-themify[data-theme="theme2"] {
//   color: #4FA4DD;
//   background: #FBFCFC;
// }
