@import '../../app/styles/vars.module.scss';
@import '../../app/styles/themify.module.scss';

.footer {
  margin-top: 30px;
}

.footer__bottom {
  width: 100%;
  height: 25px;
  margin-top: 0px; //25px beofre seo
  grid-column: 1/3;
  @include themify {
    background: theme(color--primary);
  }
}

.footer__bottom--margin {
  margin-bottom: 50px;
}

.footer__seo {
  @include themify {
    background: theme(color--secondary);
  }
  grid-column: 1/3;
  min-height: 30px;
  width: 100%;
  color: lighten(#000, 30);
  padding: 25px;
  text-align: center;
}

@media only screen and (max-width: var('page__width--max')) {
  .footer {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media all and (min-width: var('tablet')) {
  .footer__social {
    max-width: 300px;
    margin: 0 auto;
  }
}

@media all and (min-width: var('desktop')) {
  .footer {
    display: grid;
    grid-template-columns: 10fr 3fr;
  }

  .footer__social {
    max-width: unset;
    margin: unset;
  }
}
