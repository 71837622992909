@import '../../../app/styles/mixin.module.scss';

.item,
.item--compact {
  display: flex;
  align-items: stretch;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.item--gift {
  flex-wrap: wrap;
}

.item__image-box,
.item__image-box--compact {
  width: 35%;
  min-width: 100px;
  max-width: 250px;
  align-self: flex-start;
  position: relative;
}

.item__image {
  width: 100%;
  height: 100%;
}

.item__details,
.item__details--compact {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 15px;
  line-height: 2.4rem;
}

.item--cart__details {
  display: block;
}

.item--cart__price {
  width: 100%;
}

.details__name,
.details__name--compact {
  flex-basis: 100%;
  line-height: 1.8rem;
  display: flex;
  flex-direction: column;
}

.details__size,
.details__size--compact {
  flex-basis: 100%;
  line-height: 1.8;
}

.details__price,
.details__price--compact {
  min-width: 60px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  padding-bottom: 5px;
  padding-right: 0px;
}

.details__price--readonly,
.details__price--readonly--compact {
  padding-bottom: 0;
}

.details__qty,
.details__qty--compact {
  padding-top: 20px;
  text-align: right;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  text-align: center;
  align-self: flex-start;
}

.details__qty--readonly,
.details__qty--readonly--compact {
  line-height: 1.8;
}
.details__qty--readonly,
.details__qty--readonly--compact,
.details__size--readonly,
.details__size--readonly--compact {
  font-size: var('text--sm');
}

.qty__select {
  width: 70px;

  text-align: center;
  box-sizing: border-box;
}

.details__remove,
.details__remove--compact {
  flex-basis: 100%;
  text-align: right;
}

.remove__button {
  width: 115px;
  padding: 11px 0px;
}

.remove__icon {
  @include themify() {
    color: theme(color__icon--gray);
  }
}

.item__gifts {
  grid-row: 2;
  grid-column: 1/3;
  margin-bottom: 40px;
}

.item__gifts--readonly {
  margin: 0;
  padding: 0;
}

.gifts__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
}

.gifts__text {
  margin-right: 30px;
  word-break: break-all;
  margin: 0px;
  font-size: var('text--sm');
}

.gifts-text__content {
  font-size: var('text--sm');
}

.gifts__divider {
  width: 100%;
}

.gifts__delete {
  position: absolute;
  right: 0px;
  top: 5px;
}

.gifts__item--readonly,
.gifts__item--readonly--compact {
  padding-left: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

.details__no-stock,
.details__no-stock--compact,
.details__qty-action,
.details__qty-action--compact {
  padding: 5px 0px 10px;
  width: 100%;
}
.details__qty-action,
.details__qty-action--compact {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}

.no-stock__notification {
  min-width: unset;
}

.item--cart__sold-out {
  //max-width: 110px;
  padding: 0px 0px 10px 10px;
}

.qty {
  max-width: 65px;
}

@media screen and (max-width: var('phone')) {
  .price__label,
  .price__label--compact {
    font-size: var('text--sm');
  }
}

@media screen and (min-width: var('tablet--small')) {
  .gifts__item {
    padding-right: 0px;
    padding-left: 16px;
  }

  .gifts__item--readonly {
    padding-left: 0;
  }

  .qty__select {
    width: 80px;
    padding: 12px 14px;
  }

  // .item--cart__sold-out {
  //   max-width: 180px;
  // }
}

@media screen and (min-width: var('tablet')) {
  .details__no-stock {
    grid-column: 1/3;
    padding: 10px 15px 0px;
  }
  .details__qty-action {
    grid-column: 3;
  }

  .gifts__item--readonly {
    padding-left: 10px;
  }

  .item {
    display: grid;
    grid-template-columns: 1.5fr 3fr 2fr;
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .item__image-box {
    width: 100%;
    max-width: 230px;
  }

  .item__details {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: auto auto 1fr auto;
    margin: 20px 0 0 0;
    height: 100%;
  }

  .item__details--readonly {
    grid-template-columns: 7fr;
    margin-top: 0;
  }

  .details__name,
  .details__size {
    margin: 0 15px;
  }

  .details__name--readonly,
  .details__size--readonly {
    margin: 0 10px;
  }

  .details__name {
    grid-row: 1 / 2;
    min-height: 25px;
  }

  .details__size {
    grid-row: 2 / 3;
  }

  .details__price {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
    text-align: right;
    margin-top: 25px;
    margin-right: 0px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: unset;
    font-weight: 600;
  }

  .details__price--readonly {
    align-items: flex-end;
    margin-right: 0;
    padding-bottom: 5px;
  }

  .details__qty {
    margin: 0;
    padding-top: 0;
  }

  .details__qty--readonly {
    grid-row: 3 / 4;
    margin: 0 10px;
  }

  .details__remove {
    grid-row: 4 / 5;
    grid-column: 3 / 4;
    margin-top: 0;
    flex-basis: 30%;
  }

  .price__label {
    padding-top: 5px;
  }
}
