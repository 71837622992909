@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.table__row {
  padding-top: 1px;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0;
  text-align: center;
  @include themify {
    border-right: 1.5px solid theme(color--gray);
  }
}

.table__row--accent {
  @include themify {
    background-color: theme(color--gray);
    border-top: 1.5px solid theme(body__background);
  }
}

.table__cell__header--not-last {
  @include themify {
    border-right: 1.5px solid theme(body__background);
  }
}

.table__cell {
  padding: 0;
  line-height: 2.5;
  height: 100%;
  @include themify {
    border-bottom: 1.5px solid theme(color--gray);
  }
}

.table__first-row {
  padding-top: 20px;
}

.table {
  height: 100%;
  flex-grow: 1;
}

@media only screen and (min-width: var(tablet)) {
  .table {
    width: 70%;
  }
}
