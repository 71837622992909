@import './themify.module.scss';
@import './vars.module.scss';

@mixin cms-headline--mixin {
  margin: 15px 0 15px 0 !important;
  padding: 15px 0 !important;
  text-align: center !important;
  font-size: var('text--xxl') !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;

  @include themify() {
    background: theme(cms-header__background);
  }
}
