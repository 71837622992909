@import '../../../../app/styles/themify.module.scss';
@import '../../../../app/styles/vars.module.scss';

.up-sell-desktop-modal__container {
  position: fixed;
  width: 100%;
  transform: translate3d(0, 0, 0);
  overflow-y: auto;
  height: 100vh;
  max-height: 100vh;
  pointer-events: none;
}

.up-sell-desktop-modal__backdrop {
  @include themify() {
    background-color: theme(up-sell__modal__backdrop__background);
  }
}

.up-sell-desktop-modal__cart__container {
  position: absolute;
  padding-left: 10px;
  width: 510px;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: all;
}

.up-sell-desktop-modal__cart {
  height: 100%;
  @include themify() {
    background-color: theme(up-sell__modal__cart__background);
  }
  display: flex;
  flex-direction: column;
  pointer-events: all;
}

.up-sell-desktop-modal__up-sell-products {
  height: 100%;
  width: calc(100% - 500px);
  display: flex;
  align-items: center;
}

.up-sell-products__content {
  max-height: 100%;
  pointer-events: all;
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.cart__header {
  @include themify() {
    background-color: theme(modal-button__background--inactive);
    color: theme(modal-button__text--inactive);
  }
  display: flex;
  height: 54px;
  font-size: var(text--lg);
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  position: relative;
  flex-shrink: 0;
}

.header__close-button__container {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  display: flex;
  align-items: center;
}

.cart__header__close-button {
  border: none;
  cursor: pointer;
}

.cart__content {
  flex-grow: 1;
  max-height: 100%;
  overflow-y: auto;
}

.cart__content__list {
  padding-inline: 10px;
}

.up-sell-desktop-modal__footer {
  flex-shrink: 0;
  @include themify() {
    border-top: 1px solid theme(mini-cart__border);
  }
}

.up-sell-desktop-modal__total {
  @include themify() {
    grid-template-columns: 140px 1fr 20px 1fr;
  }
}