@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';

.container {
  margin-top: 16px;
}

.btn-facebook {
  display: flex;
  margin: 30px auto;
  padding: 8px 18px;
  width: calc(100% - 32px);

  > span {
    align-self: center;
  }

  > .icon {
    float: right;
    margin-left: 15px;
  }
}

.accordion {
  box-shadow: none;
  @include themify() {
    border-bottom: 1px solid theme(footer-block__line);
  }
}

.accordion__label {
  box-sizing: border-box;
  margin: 0;
  padding: 3px 15px;
  font-size: var(text--md);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.accordion__caption {
  justify-content: center;
}
