@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';

.modal-button {
  display: flex;
  width: 100%;
  padding: 15px;
  border: none;
  font-size: var(text--lg);
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
}

.modal-button--clickable {
  cursor: pointer;
}

.modal-button--inactive {
  @include themify() {
    background-color: theme(modal-button__background--inactive);
    color: theme(modal-button__text--inactive);
  }
}

.modal-button--active {
  @include themify() {
    background-color: theme(modal-button__background--active);
    color: theme(modal-button__text--active);
  }
}

.modal-button--inactive > svg {
  @include themify() {
    fill: theme(modal-button__text--inactive);
  }
}

.modal-button--active > svg {
  @include themify() {
    fill: theme(modal-button__text--active);
  }
}

.modal-button:focus {
  outline: none;
}

.modal-button__content {
  width: 100%;
  justify-content: center;
}
