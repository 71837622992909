@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.details__content-container {
  display: flex;
  flex-direction: column;
}

.details__checkbox {
  margin-bottom: 0;
}

.details__description {
  margin-left: 30px;
  margin-bottom: 10px;
}