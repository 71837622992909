@import '../../../app/styles/mixin.module.scss';
@import '../../../app/styles/themify.module.scss';

$arrow-size: 16px;
$padding-left: 20px;

.localization {
  position: relative;
  grid-row: 1;
  grid-column: 1;
  font-size: var('text--xxxl');
  align-items: center;
  padding-left: $padding-left;
  @include themify() {
    background: theme(header__background);
    border-bottom: 1px solid theme(header__border--bottom);
  }
}

.localization__flag {
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
}

.flag__icon {
  height: 24px !important;
  margin-right: 10px;
  display: block !important;
}
.flag__currency {
  font-size: var(text--sm);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include themify() {
    color: theme(color__text--primary);
  }
}

.localization__arrow {
  position: absolute;
  bottom: 0px;
  left: ($padding_left + $arrow_size);
  z-index: 101;
}

.localization__arrow:after,
.localization__arrow:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-width: 0px;
  margin-left: 0px;
}

.localization__arrow--show::before {
  border-color: rgba(136, 183, 213, 0);
  @include themify() {
    border-top-color: theme(header__border--bottom);
  }
  border-width: $arrow_size;
  margin-left: -$arrow_size;
}

.localization__arrow--show:after {
  border-color: rgba(194, 225, 245, 0);
  @include themify() {
    border-top-color: theme(header__background);
  }
  border-width: ($arrow_size - 1);
  margin-left: -($arrow_size - 1);
}

.localization__panel {
  position: relative;
  grid-row: 2;
  grid-column: 1;
  grid-column-end: 4;
}
.localization__location-switcher,
.localization__language-switcher,
.localization__currency-switcher {
  margin-top: 0px;
}

.panel__container {
  position: absolute;
  width: 100%;
  z-index: 100;
  border-top: 0px;
  @include themify() {
    background: theme(body__background);
    border: 1px solid theme(border__color);
    border-top-color: theme(color__primary);
  }
}

.panel__wrapper {
  display: grid;
  grid-template-columns: 1fr 6fr 6fr 6fr;
  column-gap: 20px;
  padding: 20px 1.72rem;
}

.localization__close {
  cursor: pointer;
}
