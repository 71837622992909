@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/themify.module.scss';

.blocks__block {
  width: 100%;
}

.block__heading {
  font-weight: bold;
  padding: 15px 15px;
  margin: 0px;
  text-transform: uppercase;
  box-sizing: border-box;
  display: block;
  font-size: var(text--md);
  letter-spacing: 1px;

  @include themify {
    border-top: 1px solid theme(color--gray-light);
  }
}

.heading__icon {
  float: right;
}

.block__list {
  padding: 0px;
  margin: 0px;
  list-style: none;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.3s linear;
}

.block__list--showed {
  max-height: 300px;
  transition: max-height 0.5s linear;
  @include themify {
    border-top: 1px solid theme(color--gray-light);
  }
}

.list__item {
  padding: 8px 15px;
  text-transform: uppercase;
  font-size: var(text--sm);
}

@media only screen and (min-width: var(desktop)) {
  .blocks {
    display: flex;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 1;
  }

  .block__list {
    max-height: unset;
  }

  .heading__icon {
    display: none;
  }

  .block__list--showed {
    border-top: 0px;
    max-height: unset;
    transition: max-height 0.3s linear;
  }
}
