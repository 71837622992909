@import '../../app/styles/themify.module.scss';

.modal__container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  @include themify() {
    background: theme(body__background);
  }
}

.modal {
  height: 100%;
  display: block;
}
