@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('../../public/fonts/content/Futura/futura.css');
@import url('../../public/fonts/content/TwinCentury/twinCentury.css');

@font-face {
  font-display: swap;
  font-family: 'nimbussancon-bold';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/nimbussancon-bol-webfont.ttf');
  src: url('../../public/fonts/content/nimbussancon-bol-webfont.eot');
  /* IE9 Compat Modes */
  src:
    url('../../public/fonts/content/nimbussancon-bol-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/fonts/content/nimbussancon-bol-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../../public/fonts/content/nimbussancon-bol-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/fonts/content/nimbussancon-bol-webfont.svg#svgFontName') format('svg');
  /* Legacy iOS */
}

@font-face {
  font-display: swap;
  font-family: 'Arial Black';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/ArialBlack.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Arial Black';
  font-weight: bold;
  font-style: bold;
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/ArialBlackBold.ttf') format('truetype');
}

/* Gotham */

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/Gotham/Gotham-Light.eot');
  src:
    local('Gotham-Light'),
    url('../../public/fonts/content/Gotham/Gotham-Light.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/content/Gotham/Gotham-Light.woff2') format('woff2'),
    url('../../public/fonts/content/Gotham/Gotham-Light.woff') format('woff'),
    url('../../public/fonts/content/Gotham/Gotham-Light.ttf') format('truetype'),
    url('../../public/fonts/content/Gotham/Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/Gotham/Gotham-LightItalic.eot');
  src:
    local('Gotham-LightItalic'),
    url('../../public/fonts/content/Gotham/Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/content/Gotham/Gotham-LightItalic.woff2') format('woff2'),
    url('../../public/fonts/content/Gotham/Gotham-LightItalic.woff') format('woff'),
    url('../../public/fonts/content/Gotham/Gotham-LightItalic.ttf') format('truetype'),
    url('../../public/fonts/content/Gotham/Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/nimbussancon-bol-webfont.ttf');
  src: url('../../public/fonts/content/nimbussancon-bol-webfont.eot');
  /* IE9 Compat Modes */
  src:
    url('../../public/fonts/content/nimbussancon-bol-webfont.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../public/fonts/content/nimbussancon-bol-webfont.woff') format('woff'),
    /* Pretty Modern Browsers */ url('../../public/fonts/content/nimbussancon-bol-webfont.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/fonts/content/nimbussancon-bol-webfont.svg#svgFontName') format('svg');
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/Gotham/Gotham-Bold.eot');
  src:
    local('Gotham-Bold'),
    url('../../public/fonts/content/Gotham/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/content/Gotham/Gotham-Bold.woff2') format('woff2'),
    url('../../public/fonts/content/Gotham/Gotham-Bold.woff') format('woff'),
    url('../../public/fonts/content/Gotham/Gotham-Bold.ttf') format('truetype'),
    url('../../public/fonts/content/Gotham/Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/Gotham/Gotham-MediumItalic.eot');
  src:
    local('Gotham-MediumItalic'),
    url('../../public/fonts/content/Gotham/Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/content/Gotham/Gotham-MediumItalic.woff2') format('woff2'),
    url('../../public/fonts/content/Gotham/Gotham-MediumItalic.woff') format('woff'),
    url('../../public/fonts/content/Gotham/Gotham-MediumItalic.ttf') format('truetype'),
    url('../../public/fonts/content/Gotham/Gotham-MediumItalic.svg#Gotham-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/Gotham/Gotham-XLight.eot');
  src:
    local('Gotham-XLight'),
    url('../../public/fonts/content/Gotham/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/content/Gotham/Gotham-XLight.woff2') format('woff2'),
    url('../../public/fonts/content/Gotham/Gotham-XLight.woff') format('woff'),
    url('../../public/fonts/content/Gotham/Gotham-XLight.ttf') format('truetype'),
    url('../../public/fonts/content/Gotham/Gotham-XLight.svg#Gotham-XLight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/Gotham/Gotham-Thin.eot');
  src:
    local('Gotham-Thin'),
    url('../../public/fonts/content/Gotham/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/content/Gotham/Gotham-Thin.woff2') format('woff2'),
    url('../../public/fonts/content/Gotham/Gotham-Thin.woff') format('woff'),
    url('../../public/fonts/content/Gotham/Gotham-Thin.ttf') format('truetype'),
    url('../../public/fonts/content/Gotham/Gotham-Thin.svg#Gotham-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Gotham';
  src: local('Calibri');
  src: local('Ubuntu');
  src: url('../../public/fonts/content/Gotham/Gotham-ThinItalic.eot');
  src:
    local('Gotham-ThinItalic'),
    url('../../public/fonts/content/Gotham/Gotham-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('../../public/fonts/content/Gotham/Gotham-ThinItalic.woff2') format('woff2'),
    url('../../public/fonts/content/Gotham/Gotham-ThinItalic.woff') format('woff'),
    url('../../public/fonts/content/Gotham/Gotham-ThinItalic.ttf') format('truetype'),
    url('../../public/fonts/content/Gotham/Gotham-ThinItalic.svg#Gotham-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
}
