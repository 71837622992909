@import './themify.module.scss';
@import './vars.module.scss';

@mixin text--mixin {
}

@mixin inner-html--mixin {
  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ul {
    list-style: disc inside;
  }

  ol {
    list-style: decimal inside;
  }
}

:global {
  .phone--visible,
  .tablet--visible,
  .tablet--small--visible,
  .desktop--visible {
    display: none !important;
  }

  @media (max-width: (var('phone'))) {
    .phone--visible {
      display: block !important;

      &.flex {
        display: flex !important;
      }

      &.grid {
        display: grid !important;
      }
    }
  }

  @media (min-width: var('phone')) and (max-width: (var('tablet--small') - 1)) {
    .tablet--small--visible {
      display: block !important;

      &.flex {
        display: flex !important;
      }

      &.grid {
        display: grid !important;
      }
    }
  }

  @media (min-width: var('tablet--small')) and (max-width: (var('tablet') - 1)) {
    .tablet--visible {
      display: block !important;

      &.flex {
        display: flex !important;
      }

      &.grid {
        display: grid !important;
      }
    }
  }

  @media (min-width: var('tablet')) {
    .desktop--visible {
      display: block !important;

      &.flex {
        display: flex !important;
      }

      &.grid {
        display: grid !important;
      }
    }
  }

  @media (max-width: (var('phone') - 1px)) {
    .phone--hidden {
      display: none;
    }
  }

  @media (min-width: var('phone')) and (max-width: (var('tablet--small') - 1)) {
    .tablet--small--hidden {
      display: none;
    }
  }

  @media (min-width: var('tablet--small')) and (max-width: (var('tablet') - 1)) {
    .tablet--hidden {
      display: none;
    }
  }

  @media (min-width: var('tablet')) {
    .desktop--hidden {
      display: none;
    }
  }
}
