.calendar__button {
  margin-top: 4px;
}

.actions {
  grid-column: 1 / 4;
  grid-row: 3;
  display: flex;
  flex-direction: row;
  padding: 15px 7px;
  width: 100%;
  justify-content: space-between;
}

.toolbar {
  display: flex;
}

.toolbar--landscape {
  grid-column: 1;
  grid-row: 1/3;
  flex-direction: column;
  justify-content: flex-start;
}

.toolbar--portrait {
  grid-column: 1/4;
  grid-row: 1;
  margin-right: 8px;
  flex-direction: row;
  align-items: flex-end;
}

.toolbar__base {
  flex: 1 1 100%;
}

.toolbar__button--portrait {
  margin-bottom: 12px;
  margin-right: 6px;
}

.toolbar__button--landscape {
  margin-left: 12px;
}

.layout__keyboard-view {
  margin-block: 16px;
  width: 276px;
}

.layout__keyboard-view--portrait {
  margin-inline: auto;
}

.layout__keyboard-view--landscape {
  margin-inline: 24px;
}

.keyboard-view__date-field {
  width: 100%;
}
