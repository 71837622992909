@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.mini-cart-total__summary {
  height: 150px;
  padding: 10px 10px 35px 10px;
  grid-template-columns: 210px 1fr 20px 1fr;
  grid-template-rows: 0.75fr 1fr;
  display: grid;
}

.mini-cart-total__summary-text {
  text-transform: uppercase;
}

.mini-cart-total__summary-text:first-of-type,
.mini-cart-total__button {
  grid-column: 2;
}

.mini-cart-total__buttons {
  display: flex;
  grid-column: 1 / -1;
  gap: 10px;
  justify-content: flex-end;
}

.buttons__button {
  margin: 10px 0;
  max-width: 50%;
}

.buttons__go-to-cart {
  width: 170px;
}

.mini-cart-total__summary-text:last-of-type,
.mini-cart-total__button:last-child {
  grid-column: 4;
  justify-self: flex-end;
}

.mini-cart-total__summary-text:last-of-type {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mini-cart-total__subtotal {
  grid-column: 1;
  padding-right: 20px;
  justify-self: flex-end;
}

.mini-cart-total__price {
  grid-column: 2;
  justify-self: flex-end;
  padding-right: 10px;
}
