@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.catalog-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  isolation: isolate;
  @include themify() {
    border-top: 2px solid theme(catalog-item__border);
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;

  @include themify() {
    .catalog-item__info {
      -webkit-transition: background-color 4000ms linear;
      -ms-transition: background-color 4000ms linear;
      transition: background-color 4000ms linear;
    }
    &:nth-child(2n-1) {
      border-right: 2px solid theme(catalog-item__border);
    }
    &:nth-child(4n) .catalog-item__info {
      background-color: theme(catalog-item4__background);
    }
    &:nth-child(4n-1) .catalog-item__info {
      background-color: theme(catalog-item3__background);
    }
    &:nth-child(4n-2) .catalog-item__info {
      background-color: theme(catalog-item2__background);
    }
    &:nth-child(4n-3) .catalog-item__info {
      background-color: theme(catalog-item1__background);
    }
  }

  @media screen and (min-width: (var('phone'))) {
    width: 50%;
  }

  @media screen and (min-width: (var('tablet'))) {
    width: 25%;
    border-top: 0;

    &:nth-child(2n-1) {
      border-right: none;
    }

    &:nth-child(4n-1),
    &:nth-child(4n-2),
    &:nth-child(4n-3) {
      @include themify() {
        border-right: 2px solid theme(catalog-item__border);
      }
    }
  }
}

.catalog-item--double-column {
  @include themify() {
    width: 50%;
  }
}

.item__quick-add {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
}

.item__quick-discount {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  font-size: var(text--l);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  @include themify() {
    background: lighten(theme(color--primary), 8%);
    color: theme(color__text--primary);
  }
}

.catalog-item__img-container {
  padding-bottom: 0;
  width: 100%;
  justify-content: center;
  display: flex;
  position: relative;
}

.catalog-item__img,
.catalog-item__img-link {
  max-height: 600px;
  max-width: 100%;
  width: 100%;
  display: block;
  min-height: 330px;
  @media screen and (min-width: (var('phone'))) {
    min-height: 250px;
  }
  @media screen and (min-width: (var('tablet--small'))) {
    min-height: 360px;
  }
  @media screen and (min-width: (var('tablet'))) {
    min-height: 256px;
  }
  @media screen and (min-width: (var('desktop'))) {
    min-height: 380px;
  }
}

.catalog-item__img {
  object-fit: cover;
}

.catalog-item--double-column__img, 
.catalog-item--double-column__img-link {
  @include themify() {
    min-height: 250px;
  }
}

.catalog-item__info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  flex-grow: 1;
  gap: 6px;
  @include themify() {
    color: theme(catalog-item__info);
  }
  text-align: center;
  font-size: var('text--sm');

  span {
    font-weight: 500;
  }
  min-height: 107px;
}

.catalog-item__print-name {
  letter-spacing: 0.075rem;
  font-weight: 600;
  text-transform: uppercase;
  overflow: hidden;
}

// .catalog-item__print-name--category {
//   height: 2.5rem;
//   display: flex;
//   justify-content: center;
//   align-items: flex-end;
//   @media screen and (min-width: (var('tablet'))) {
//     height: 2rem;
//   }
// }

.catalog-item__name {
  overflow: hidden;
  letter-spacing: 0.05rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 2.5rem;
  text-transform: uppercase;
  > span {
    max-height: 2.5rem;
  }
}

.catalog-item__category {
  @media screen and (min-width: (var('tablet'))) {
    > p {
      font-size: var(text--l);
      font-weight: 600;
    }

    > span {
      margin-top: 10px;
      font-size: var(text--sm);
    }
  }
}

.catalog-item__sale {
  display: inline-block;
  margin-right: 10px;
}
.catalog-item__old-price {
  display: inline-block;
  margin-right: 10px;
  text-decoration: line-through;
}

.catalog-item__features {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}

.catalog-item__features--hidden {
  display: none;
}

.features__feature {
  padding: 2px 8px;
  white-space: nowrap;
  @include themify() {
    background: theme(catalog-item-feature__background);
  }
}

.features__feature:last-child {
  text-overflow: ellipsis;
  overflow: hidden;
}

.catalog-item__event {
  position: absolute;
  z-index: 2;
  bottom: 66px;
  right: 0;
  max-width: 100%;
  padding: 2px 8px;
  font-weight: 700;
  @include themify() {
    background: theme(catalog-item-event__background);
    color: theme(catalog-item-event__color);
    box-shadow: theme(catalog-item-event__box-shadow);
  }
}