@import '../../../app/styles/themify.module.scss';
@import '../../../app/styles/vars.module.scss';

.mini-cart__item {
  display: grid;
  grid-template-columns: 1fr 30px 3fr;
  padding: 0 10px 0 0;
}

.mini-cart__image {
  max-width: 190px;
  grid-row: 1;
  grid-column: 1;
}

.mini-cart__details {
  grid-row: 1;
  grid-column: 3;
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr 30px 1fr 1fr;
  width: 100%;
  height: 100%;
  align-self: flex-start;
  padding-top: 20px;
  max-height: 196px;
  @include themify() {
    border-bottom: 1px solid theme(mini-cart__border);
  }
}

.mini-cart__name {
  font-size: var('text--md');
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row: 1;
}

.mini-cart__printName {
  font-size: var('text--md');
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 2;
  align-self: flex-end;
}

.mini-cart__category {
  margin-bottom: 10px;
  font-weight: bold;
  grid-row: 1;
}

.mini-cart__delete {
  cursor: pointer;
  grid-row: 1 / 3;
  grid-column: 3;
  align-self: center;
  justify-self: flex-end;
  margin-right: 10px;
}

.mini-cart__label {
  text-transform: uppercase;
  font-weight: bold;
  grid-row: 4;
}

.mini-cart__content {
  grid-row: 5;
}

.mini-cart__size {
  grid-column: 1;
}

.mini-cart__quantity {
  grid-column: 2;
  justify-self: center;
}

.mini-cart__price {
  grid-column: 3;
  justify-self: flex-end;
}
