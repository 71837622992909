.container {
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
}

.form__submit {
  display: 'flex';
  flex-grow: 1;
  margin: '10px 0px 0px 0px';
  width: '100%';
}

.form__password-reset {
  align-self: center;
  margin-top: 10px;
}

.form__element--hidding {
  display: flex;
  transition: opacity 600ms;
  opacity: 0;
}

.form__element--hidden {
  height: 0px;
  margin: 0px;
  display: none;
}
