@import '../../../app/styles/vars.module.scss';

.no-products__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
}

.no-products__label {
  font-size: var(text--xxl);
  text-transform: uppercase;
  font-weight: 500;
  line-height: var(header__line-height);
  padding-inline: 16px;
  text-align: center;
}
