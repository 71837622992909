@import './mixin.module.scss';
@import './vars.module.scss';
@import './fonts.module.scss';

// Used to ensure that Google Maps Autocomplete modal (with .pac-container class) stays in front of other elements
:global(.pac-container) {
  z-index: 9999 !important;
}

:global(body.address-book-form div[data-lastpass-root]) {
  display: none !important;
}

:global {
  .tf-v1-sidetab .tf-v1-sidetab-button-text {
    font-size: var('text--md');
  }
  .tf-v1-sidetab .tf-v1-sidetab-button-icon {
    font-size: var('text--md');
    width: 18px;
    height: 18px;
  }

  .tf-v1-sidetab .tf-v1-sidetab-button-icon > svg {
    width: 20px;
    height: 20px;
  }

  .tf-v1-sidetab .tf-v1-sidetab-button {
    padding: 0px 8px;
  }

  .tf-v1-sidetab .tf-v1-sidetab-button {
    height: 32px;
    width: 150px;
  }
}

@media (max-width: (var('tablet'))) {
  :global(.pac-container__focused) {
    top: 46px !important;
    left: 0 !important;
  }
}

:global {
  *,
  *:before,
  *:after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

  *:focus {
    outline: unset;
  }

  html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  input[type='text'],
  input[type='email'],
  input[type='search'],
  input[type='password'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  a,
  a:active,
  a:hover,
  a:link {
    text-decoration: none;
  }

  a,
  button {
    -webkit-tap-highlight-color: transparent;
  }

  a.active:not([role='button']),
  a.active:not([role='button']):active,
  a.active:not([role='button']):visited,
  a.active:not([role='button']):link {
    text-decoration: none;
    cursor: pointer;
    @include themify() {
      color: theme(color__link--active);
    }
  }

  a.active:not([role='button']):hover {
    text-decoration: none;
    @include themify() {
      color: theme(color__link);
    }
  }

  a:not([role='button']),
  a:not([role='button']):link,
  a:not([role='button']):visited,
  a:not([role='button']):focus {
    @include themify() {
      color: theme(color__link);
    }
  }

  a:not([role='button']):hover {
    @include themify() {
      color: theme(color__link--hover);
    }
  }

  /*  Tricky trick for ugly windows 10 scaling*/
  @media (-webkit-min-device-pixel-ratio: 1.25) {
    body.windows10-old-hack {
      zoom: 80%;
    }
  }

  @media (-webkit-min-device-pixel-ratio: 1.5) {
    body.windows10-old-hack {
      zoom: 66.66%;
    }
  }

  @media (-webkit-min-device-pixel-ratio: 1.75) {
    body.windows10-old-hack {
      zoom: 58%;
    }
  }

  body {
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    background: #ffffff;
    font-family: 'Montserrat', 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    line-height: 1.4285em;
    color: #000;
    min-height: 100vh;
    min-width: 360px;
    max-width: 100%;
  }

  html {
    min-width: 320px;
    max-width: 100%;
    min-height: 100vh;
    font-size: 14px;
    text-rendering: optimizeLegibility;
    font-weight: 400;
    letter-spacing: 0.05em;
  }

  html.modal-open,
  body.modal-open {
    overflow: hidden;
  }

  html,
  body {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }

  body.apple input,
  body.apple select,
  body.apple textarea {
    font-size: 16px;
  }

  strong {
    font-weight: 700;
  }
}

// Switching animation & app
:global {
  #app {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 100vh;
  }

  .slide-main-enter {
    transform: translateX(-100%);
    opacity: 0;
  }

  .slide-main-enter.slide-main-enter-active {
    transform: translateX(0%);
    transition-delay: 150ms;
    transition: 500ms linear;
    opacity: 1;
  }

  .slide-main-exit {
    transform: translateX(0%);
    opacity: 1;
  }

  .slide-main-exit-done,
  .slide-main-exit.slide-main-exit-active {
    transform: translateX(-100%);
    transition-delay: 150ms;
    transition: 500ms linear;
    opacity: 0;
  }

  .slide-opposite-enter {
    transform: translateX(100%);
    opacity: 0;
  }

  .slide-opposite-enter.slide-opposite-enter-active {
    transform: translateX(0%);
    transition-delay: 150ms;
    transition: 500ms linear;
    opacity: 1;
  }

  .slide-opposite-exit {
    transform: translateX(0%);
    opacity: 1;
  }

  .slide-opposite-exit-done,
  .slide-opposite-exit.slide-opposite-exit-active {
    transform: translateX(100%);
    transition-delay: 150ms;
    transition: 500ms linear;
    opacity: 0;
  }
}
