@import '../../../app/styles/vars.module.scss';
@import '../../../app/styles/mixin.module.scss';

.form-control {
  min-width: 100%;
  max-width: 350px;
}

.label {
  font-size: var('text--lg');
}

.text-field,
.select {
  font-size: var('text--lg');
  @include themify() {
    margin-top: 5px;
  }
}

.select,
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item--hidden {
  display: none;
}
