@import '../../app/styles/vars.module.scss';
.spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  flex-direction: column;
}
.spinner--image {
  min-height: 45px;
}

.spinner--image--relative {
  position: relative !important;
  min-height: 80px;
  padding: 20px 0px;
}

.spinner--margins {
  padding: 30px;
}

.spinner__loader {
  position: relative;
  z-index: 50;
  padding: 15px 0px;
}

.spinner__loader--base {
  display: flex;
}

.loader__image {
  position: absolute;
  font-size: 60px;
  left: 10px;
  top: 25px;
}
