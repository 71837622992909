@import '../../../app/styles/vars.module.scss';

.no-products__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
}

.container__label {
  font-size: var(text--xxl);
  text-transform: uppercase;
  font-weight: 500;
  line-height: var(header__line-height);
  padding-inline: 16px;
  text-align: center;
}

.container__links-container {
  display: flex;
  gap: 8px;
  padding-inline: 16px;
  flex-direction: column;
  width: 100%;
}

.links-container__button {
  width: 100%;
  letter-spacing: 0.05em;
}

.top-sell__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (min-width: var('tablet--small')) {
  .container__links-container {
    flex-direction: row;
  }

  .container__links-container {
    width: max-content;
  }

  .links-container__button {
    width: max-content;
  }
}