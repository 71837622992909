.form {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}

.form__submit {
  margin-top: 20px;
  width: 100%;
}