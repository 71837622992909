@import '../../app/styles/mixin.module.scss';

.quick-add {
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 100;
  @include themify() {
    background: transparentize(theme(color--gray-light), 0.1);
  }
  display: flex;
  visibility: hidden;
  opacity: 0;
  flex-direction: column;
  cursor: default;
  transition: opacity 400ms;
}

.quick-add--double-column {
  @include themify() {
    padding: 10px 4px 4px 4px;
    max-height: 140px;
    overflow: auto;
  }
}

.quick-add--visible {
  visibility: visible;
  opacity: 1;
}

.quick-add__heading {
  font-weight: 600;
  text-transform: uppercase;
  font-size: var('text--xs');
  text-align: center;
}

.quick-add__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.items__item {
  cursor: pointer;
  width: 40px;
  min-width: unset;
  height: 40px;
  padding: 0px;
  margin: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include themify() {
    background: theme(color--gray);
    border: 1px solid theme(border__color);
  }
}

.items__item:active {
  @include themify() {
    background: theme(color--primary);
    color: theme(color__text--primary);
  }
}

.items__item--disabled,
.items__item--disabled:hover {
  cursor: default;
  @include themify() {
    background: theme(border__color);
    border-color: theme(border__color);
    color: theme(color__text--disabled);
  }
}

@media (min-width: var('tablet')) {
  .items__item:hover:not(.items__item--disabled) {
    @include themify() {
      background: theme(color--primary);
      color: theme(color__text--primary);
    }
  }

  .items__item {
    width: 34px;
    height: 34px;
  }
}
