@import '../../app/styles/themify.module.scss';
@import '../../app/styles/vars.module.scss';

.social {
  padding: 15px 5px;
  display: flex;
  justify-content: space-around;
}

.item__icon {
  color: #000;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  -ms-transition: -ms-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  width: 2.5rem;
  height: 2.5rem;
}

.item__icon--inversed {
  color: #fff;
}

.item__icon:hover {
  cursor: pointer;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);

  @include themify() {
    color: theme('color--primary') !important;
  }
}

.item__icon--inversed:hover {
  @include themify() {
    color: theme('color--secondary') !important;
  }
}

@media only screen and (min-width: var('tablet')) {
  .social {
    grid-row: 2;
    grid-column: 2;
    justify-content: space-between;
    padding: 15px 15px;
  }
}

@media only screen and (min-width: var('desktop')) {
  .item__icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
